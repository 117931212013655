








































import { Vue, Component } from "vue-property-decorator";

@Component
export default class AccountLayout extends Vue {
  title = "";

  setTitle(title: string) {
    this.title = title;
  }
}
